import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import { MetaDescription, Title } from "../components/metadata";
import { Link } from "../components/basic";

const title = "Contact";

const Metadata = () => (
  <>
    <Title>{title}</Title>
    <MetaDescription>
      Get in touch with me via e-mail or LinkedIn.
    </MetaDescription>
  </>
);

// This is not called Honeypot because Styled-Components includes the component name in the CSS class name and we want
// this field to look as real as possible, to deceive bots
const TermsOfService = styled.input.attrs({
  type: "checkbox",
  tabIndex: "-1",
  autoComplete: "off",
})`
  display: none !important;
`;

const SubmitButton = styled.button.attrs({
  className: "pure-button pure-button-primary",
  type: "submit",
})`
  background-color: #0075e2;
`;

const Contact = () => (
  <Layout title={title}>
    <Metadata />
    {/* eslint-disable-next-line react/jsx-one-expression-per-line, prettier/prettier */}
    <p>If you’d like to get in touch, please send me a message through <Link to="https://linkedin.com/in/josegonzalezjmgq">LinkedIn</Link>, or use the form below. I’ll get back to you as soon as possible.</p>

    <form
      className="pure-form"
      name="contact"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="agree-terms-service"
    >
      <div className="pure-g">
        <div className="pure-u-1 pure-u-sm-2-3">
          <fieldset className="pure-group">
            {/* This hidden field is necessary for Netlify Forms to work. It's value must be the same as the form name */}
            <input type="hidden" name="form-name" value="contact" />
            {/* Honeypot field, to lure bot users into completing a field that human users can't see */}
            <TermsOfService name="agree-terms-service" />
            <input
              type="text"
              className="pure-input-1"
              name="name"
              placeholder="Name"
              aria-label="Name"
              required
            />
            <input
              type="email"
              className="pure-input-1"
              name="email"
              placeholder="Email"
              aria-label="Email"
              required
            />
            <textarea
              className="pure-input-1"
              name="message"
              placeholder="Message"
              aria-label="Message"
              rows="5"
              required
            />
          </fieldset>
        </div>
      </div>
      <SubmitButton>Submit</SubmitButton>
    </form>
  </Layout>
);

export default Contact;
